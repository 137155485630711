<template>
   <com-menu-body
      title="企业概况"
      :menu="menu"
      :banner="[require('@/assets/imgs/banner_for_company.jpg')]"
   ></com-menu-body>
</template>

<script>
import ComMenuBody from "@/components/MenuBody";

export default {
   name: "PageCompany",
   components: {
      ComMenuBody,
   },
   data() {
      return {
         menu: [
            { label: "企业简介", path: "/company/introduce" },
            { label: "领导班子", path: "/company/leader" },
            { label: "组织机构", path: "/company/organization" },
            { label: "发展历程", path: "/company/develop" },
            { label: "大事记", path: "/company/milestone" },
            { label: "企业文化", path: "/company/culture" },
         ],
      };
   },
};
</script>

<style lang="scss" scoped></style>
