<template>
   <div class="develop">
      <div class="develop-item">
         <div class="develop-item__date">2003</div>
         <div class="develop-item__inner">
            <p>
               湖南中烟工业有限责任公司前身系成立于2003年5月的湖南中烟工业公司，隶属国家烟草专卖局（中国烟草总公司）
            </p>
         </div>
      </div>
      <div class="develop-item">
         <div class="develop-item__date">2006</div>
         <div class="develop-item__inner">
            <p>
               2006年10月，经国家烟草专卖局批准，湖南中烟工业公司与所属长沙卷烟厂、常德卷烟厂实现一体化重组。
            </p>
         </div>
      </div>
      <div class="develop-item">
         <div class="develop-item__date">2007</div>
         <div class="develop-item__inner">
            <p>
               2007年11月，按照建立现代企业制度的要求，更名改制为湖南中烟工业有限责任公司
            </p>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "PageCompanyDevelop",
};
</script>

<style lang="scss" scoped>
.develop {
   position: relative;
   margin-top: 8px;

   &-item {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-bottom: 40px;

      &::before {
         content: "";
         position: absolute;
         left: 90px;
         top: 0;
         bottom: 0;
         width: 1px;
         background: var(--color-main);
      }

      &:first-child::before {
         top: 10px;
      }

      &:last-child::before {
         bottom: auto;
         height: 10px;
      }

      &:first-child {
         margin-top: 0;
      }

      &__date {
         position: relative;
         width: 90px;
         margin-right: 40px;
         font-size: 24px;
         font-weight: 600;
         line-height: 1;

         &::after {
            content: "";
            position: absolute;
            left: 90px;
            top: 50%;
            width: 17px;
            height: 17px;
            margin: -7px 0 0 -8px;
            border-radius: 50%;
            background: var(--color-main);
         }
      }

      &__inner {
         overflow: hidden;

         p {
            font-size: 16px;

            &:first-child {
               margin-top: 2px;
            }
         }
      }
   }
}
</style>
