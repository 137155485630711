<template>
   <com-new-list
      url="post?alias=milestone"
      show-date
      pagination
      size="medium"
   ></com-new-list>
</template>

<script>
import ComNewList from "@/components/NewList";

export default {
   name: "PageCompanyMilestone",
   components: {
      ComNewList,
   },
};
</script>

<style lang="scss" scoped></style>
