<template>
   <div class="leader">
      <p>
         <span class="leader-name">
            <span>籍</span>
            <span>涛</span>
         </span>
         党组书记、总经理
      </p>
      <p>
         <span class="leader-name">
            <span>刘</span>
            <span>兴</span>
         </span>
         一级高级经理
      </p>
      <p>
         <span class="leader-name">栾永亮</span>
         党组成员、副总经理
      </p>
      <p>
         <span class="leader-name">
            <span>李</span>
            <span>立</span>
         </span>
         党组成员、副总经理
      </p>
      <p>
         <span class="leader-name">龚道国</span>
         党组成员、副总经理、公司工会主席
      </p>
      <p>
         <span class="leader-name">金铁龙</span>
         党组成员、副总经理
      </p>
      <p>
         <span class="leader-name">徐双红</span>
         党组成员、副总经理、机关党委书记
      </p>
      <p>
         <span class="leader-name">毛华初</span>
         党组成员、副总经理
      </p>
      <p>
         <span class="leader-name">丁连勇</span>
         党组成员、纪检组长
      </p>
      <p>
         <span class="leader-name">钟科军</span>
         总工程师
      </p>
      <p>
         <span class="leader-name">范康君</span>
         二级高级经理
      </p>
      <p>
         <span class="leader-name">李志华</span>
         二级高级经理
      </p>
   </div>
</template>

<script>
export default {
   name: "PageCompanyLeader",
};
</script>

<style lang="scss" scoped>
.leader {
   p {
      margin: 1.2em 0;
      font-size: 16px;
      color: var(--color-normarl);

      &:first-child {
         margin-top: 0;
      }
   }

   &-name {
      display: inline-flex;
      justify-content: space-between;
      min-width: 3em;
      margin-right: 10px;
      font-weight: 700;
      font-size: 110%;
   }
}
</style>
