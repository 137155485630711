<template>
   <div class="culture">
      <img class="culture-wewnhua_img" src="./imgs/qiyewenhua.png" />

      <div class="poverty">
         <router-link to="/lists/?type=huodong" class="poverty-plate">
            <div class="poverty-plate__title">文化主题活动：致敬奋斗者</div>
         </router-link>
         <div class="poverty-bottom">
            <div class="poverty-carousel">
               <div class="poverty-carousel__inner">
                  <button
                     class="poverty-carousel__button poverty-carousel__button--left"
                     @click="$refs.carousel.prev()"
                  >
                     <i class="el-icon-arrow-left"></i>
                  </button>
                  <button
                     class="poverty-carousel__button poverty-carousel__button--right"
                     @click="$refs.carousel.next()"
                  >
                     <i class="el-icon-arrow-right"></i>
                  </button>

                  <el-carousel
                     ref="carousel"
                     height="250px"
                     :autoplay="false"
                     indicator-position="none"
                     @change="carouselIndex = $event"
                  >
                     <el-carousel-item
                        v-for="(item, i) in page"
                        :key="i"
                        class="carousel-item"
                     >
                        <el-row :gutter="20">
                           <el-col
                              v-for="(v, k) in articles.slice(
                                 i * 3,
                                 3 * (i + 1),
                              )"
                              :key="k"
                              :span="8"
                              :offset="0"
                           >
                              <router-link
                                 :to="{ path: '/article', query: { id: v.id } }"
                              >
                                 <el-card :body-style="{ padding: '0px' }">
                                    <img :src="v.cover" class="image" />
                                    <div class="carousel_foot">
                                       <p>{{ v.title }}</p>
                                    </div>
                                 </el-card>
                              </router-link>
                           </el-col>
                        </el-row>
                     </el-carousel-item>
                  </el-carousel>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { Carousel, CarouselItem, Row, Col, Card, Icon } from "element-ui";
import { http } from "@/http";

export default {
   name: "PageCompanyCulture",
   components: {
      [Row.name]: Row,
      [Col.name]: Col,
      [Card.name]: Card,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Icon.name]: Icon,
   },
   data() {
      return {
         total: 0,
         page: 0,
         articles: [],
      };
   },
   created() {
      this.getArticles();
   },
   methods: {
      /**
       * 获取文章列表
       */
      getArticles() {
         const params = { p: 1, limit: 1000 };
         http.get("post?alias=huodong", { params }).then((res) => {
            const {
               data: { data, total },
            } = res;
            this.articles = data;
            this.total = total;
            this.page = parseInt(total / 3, 10) + 1;
         });
      },
   },
};
</script>

<style lang="scss" scoped>
a {
   text-decoration-line: none;
   text-decoration: none;
}
.image {
   width: 100%;
}
.el-row {
   padding: 0 5%;
}
.el-carousel__arrow--left {
   left: 10px;
   font-size: 20px;
   color: #d5b57a;
   background-color: #e8dbba;
}

.clearfix:before,
.clearfix:after {
   display: table;
   content: "";
}

.clearfix:after {
   clear: both;
}
.culture {
   &-wewnhua_img {
      display: block;
      width: 80%;
      max-width: 1444px;
      text-align: center;
      margin: 0 auto;
   }
   p {
      font-size: 16px;

      &:first-child {
         margin-top: 6px;
      }
   }
   &-line_title {
      background-color: #f8f8f8;
      height: 45px;
      margin: 20px 0 40px 0;
   }
   &-line_title span {
      color: #fff;
      font-size: 20px;
      line-height: 45px;
      padding: 11.2px;
      background-color: #e1ba79;
   }
}
.carousel_foot {
   padding: 0.5em 0.6em;
}
.carousel_foot p {
   font-size: 1em;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   text-overflow: ellipsis;
   overflow: hidden;
}
.poverty {
   &-plate {
      display: flex;
      margin-bottom: 20px;
      background: #f8f8f8;

      &__title {
         min-width: 150px;
         padding: 0 1em;
         font-size: 18px;
         font-weight: 600;
         color: #fff;
         line-height: 48px;
         text-align: center;
         background: var(--color-main);
      }
   }

   &-bottom {
      padding: 40px 50px;
      &__title {
         margin-bottom: 20px;
         font-size: 20px;
         text-align: center;
         font-weight: 600;
      }
   }

   &-carousel {
      margin: 0 auto;

      ::v-deep .el-carousel__arrow {
         display: none;
      }

      &__inner {
         position: relative;
      }

      &__button {
         position: absolute;
         top: 50%;
         z-index: 10;
         width: 40px;
         height: 40px;
         margin-top: -20px;
         color: #d1a861;
         font-size: 18px;
         font-weight: 600;
         background: #e9d9bd;
         border-radius: 50%;
         cursor: pointer;

         &:hover {
            background: #ddccad;
         }

         &--left {
            right: 100%;
            margin-right: 10px;
         }

         &--right {
            left: 100%;
            margin-left: 10px;
         }
      }

      &__img {
         height: 100%;
         background: #f2f2f2 center / cover no-repeat;
      }

      &__content {
         font-size: 16px;
         color: var(--colo-main);
         color: var(--color-base);
      }
   }
}
</style>
