<template>
   <img style="" class="organizational" src="./imgs/organizationa.jpg" />
</template>

<script>
export default {
   name: "PageCompanyOrganization",
};
</script>

<style lang="scss" scoped>
.organizational {
   display: block;
   max-width: 1444px;
   text-align: center;
   margin: 0 auto;
}
</style>
