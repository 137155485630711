<template>
   <div class="introduce">
      <p>
         湖南中烟工业有限责任公司是中国烟草总公司单独出资设立的大型国有企业，前身为湖南烟草工商分设后于2003年成立的湖南中烟工业公司。2006年10月，湖南中烟工业公司与所属长沙卷烟厂、常德卷烟厂合并重组为一个企业法人；2007年11月，湖南中烟工业公司改制更名为湖南中烟工业有限责任公司。
      </p>
      <p>
         目前，公司下辖6家卷烟厂（长沙、常德、郴州、零陵、四平、吴忠卷烟厂）和4家全资子公司（投资公司、物流公司、金叶薄片公司、深圳白沙物流），持有河北白沙烟草有限责任公司50%的股权，控股3家复烤企业（浏阳天福公司、常德芙蓉公司、湘西鹤盛公司）。公司总部内设19个部门，以及市场营销、技术、原料采购3个中心。
      </p>
      <p>
         公司认真贯彻国家烟草专卖局党组和湖南省委省政府决策部署，坚持“守正创新、融合奋进”企业精神、“品质立企、品牌强企”企业方针、“踏实扎实、清廉担当”行为信条，实施“双新一体”发展战略，积极抓党建、严整改、强管理、促融合、稳增长、增后劲，各方面工作取得新成效，主要经济指标位居行业工业企业前列。
      </p>
   </div>
</template>

<script>
export default {
   name: "PageCompanyIntroduce",
};
</script>

<style lang="scss" scoped>
.introduce {
   p {
      text-indent: 2em;
      font-size: 16px;
      color: var(--color-base);
      line-height: 1.7;
   }
}
</style>
