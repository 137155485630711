<template>
   <com-new-list
      ref="new_list"
      :url="`post?alias=${type}`"
      show-date
      size="medium"
      pagination
   ></com-new-list>
</template>

<script>
import ComNewList from "@/components/NewList";

export default {
   name: "PageListsIndex",
   components: {
      ComNewList,
   },
   data() {
      return {
         type: this.$route.query.type,
      };
   },
   watch: {
      $route(newValue) {
         this.$refs.new_list.url = `post?alias=${newValue.query.type}`;
         this.$refs.new_list.getData();
      },
   },
   created() {},
};
</script>
